<div class="docs-container flex flex-row">
  <div class="hidden md:block md:w-4 lg:w-3">
    <ng-container *ngTemplateOutlet="menuContainer"></ng-container>
  </div>
  <div class="flex-grow-1 h-screen" *ngIf="iframeUrl">
    <iframe [src]="iframeUrl | sanitize" allowfullscreen width="100%" height="100%" frameborder="0"></iframe>
  </div>
</div>

<fizjo-pro-bottom-toolbar #mobileToolbar [items]="[]" (stateChange)="changeMenuState()"></fizjo-pro-bottom-toolbar>

<ng-template #menuContainer>
  <p-menu
    styleClass="w-full"
    [model]="menuItems"
    [ngClass]="{ 'docs-menu--folded': isMobileMenuFolded, 'docs-menu--unfolded': !isMobileMenuFolded }">
    <ng-template pTemplate="start">
      <div class="flex align-items-center justify-content-center my-3">
        <img src="/assets/fizjopro-logo-horizontal-lightbg-color.svg" height="40" class="md:inline mr-2" />
      </div>
    </ng-template>
    <ng-template pTemplate="submenuheader" let-item>
      <div class="flex flex-row gap-2 align-items-center">
        <span [ngClass]="item.icon"></span>
        <span class="font-bold">{{ item.label }}</span>
      </div>
    </ng-template>
    <ng-template pTemplate="item" let-item>
      <a
        pRipple
        class="flex align-items-center p-menuitem-link docs-menuitem pl-4"
        (click)="onClick(item.state.iframeId); mobileToolbar.toggleFold()"
        [routerLink]="['/docs', item.state.iframeId]"
        [routerLinkActive]="['is-active']">
        <div class="flex flex-row gap-2 align-items-center">
          <span [ngClass]="item.icon"></span>
          <span>{{ item.label }}</span>
        </div>
      </a>
    </ng-template>
  </p-menu>
</ng-template>

<p-dialog [(visible)]="shouldShowMenuDialog" position="top" class="max-h-screen">
  <ng-template pTemplate="headless">
    <div class="overflow-hidden">
      <p-scrollPanel [style]="{ height: '640px' }">
        <ng-container *ngTemplateOutlet="menuContainer"></ng-container>
      </p-scrollPanel>
    </div>
  </ng-template>
</p-dialog>
